body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #fff9e6; 
}

.container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

h1 {
    color: #333;
    margin-bottom: 20px;
}

.language-selection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.language-selection select {
    width: 150px;
    padding: 5px;
    font-size: 16px;
    margin: 0 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
}

.swap-button {
    font-size: 18px;
    padding: 5px 10px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.swap-button:hover {
    background-color: #0056b3;
}

.text-areas {
    display: flex;
    justify-content: space-between; 
    margin-bottom: 20px;
}

.text-areas div {
    margin: 0 10px;
    width: 100%; 
}

textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ddd;
    resize: vertical;
    background-color: #fff;
    height: 400px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}

.input-text-area,
.output-text-area {
    width: 100%;
    max-width: 500px; 
    position: relative;
}

.info-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e9ecef; 
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
}

.char-count {
    font-size: 14px;
    color: #888;
}

.copy-button {
    font-size: 14px;
    padding: 5px 10px;
    border: none;
    background-color: #000; 
    color: #fff; 
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.copy-button:hover {
    background-color: #333; 
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
}

button {
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    background-color: #28a745;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #218838;
}

button:disabled {
    background-color: #ccc; /* 禁用状态 */
    cursor: not-allowed;
}

.message {
    font-size: 16px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
}

.success {
    background-color: #d4edda; 
    color: #155724; 
}

.error {
    background-color: #f8d7da; 
    color: #721c24;
}

.footer {
    margin-top: 20px;
    font-size: 14px;
    color: #666;
}

.footer a {
    color: #007bff;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}